"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _EmbedChatManager_instances, _EmbedChatManager_PAGE_ID, _EmbedChatManager_CONFIG, _EmbedChatManager_IFRAME, _EmbedChatManager_isMobile, _EmbedChatManager_checkViewport, _EmbedChatManager_listenIframe, _EmbedChatManager_scrollToHeader, _EmbedChatManager_disableScrollAndRefresh, _EmbedChatManager_enableScrollAndRefresh, _EmbedChatManager_disableParentScaling, _EmbedChatManager_changeIframePosition, _EmbedChatManager_changeIframeSize, _EmbedChatManager_initIframe;
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmbedChatManager = void 0;
const env_1 = require("./env");
const base_1 = require("./base");
/**quản lý sdk embed chat */
class EmbedChatManager extends base_1.Base {
    constructor(page_id, config, is_debug) {
        /** khai báo lớp cơ sở */
        super(is_debug);
        _EmbedChatManager_instances.add(this);
        /**id trang */
        _EmbedChatManager_PAGE_ID.set(this, void 0);
        /**cấu hình hiển thị */
        _EmbedChatManager_CONFIG.set(this, void 0);
        /**iframe bong bóng chat */
        _EmbedChatManager_IFRAME.set(this, void 0);
        /** kiểm tra id trang có tồn tại không */
        if (!page_id)
            throw 'MISSING_PAGE_ID';
        /** khởi tạo id trang */
        __classPrivateFieldSet(this, _EmbedChatManager_PAGE_ID, page_id, "f");
        /** khởi tạo cấu hình */
        __classPrivateFieldSet(this, _EmbedChatManager_CONFIG, config || {}, "f");
        /** khởi tạo iframe */
        __classPrivateFieldSet(this, _EmbedChatManager_IFRAME, document.createElement('iframe'), "f");
        /** Kiểm tra meta viewport khi khởi tạo */
        this.HAS_VIEWPORT = !!document.querySelector('meta[name="viewport"]');
    }
    /**khởi động bong bóng chat */
    init() {
        this.debug('init', 'page_id', __classPrivateFieldGet(this, _EmbedChatManager_PAGE_ID, "f"));
        this.debug('init', 'config', JSON.stringify(__classPrivateFieldGet(this, _EmbedChatManager_CONFIG, "f"), null, 4));
        this.debug('Version : 2.0');
        /** kiểm tra viewport trên thiết bị di động và thay đổi nếu cần */
        __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_checkViewport).call(this);
        /** chặn zoom trên mobile */
        __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_disableParentScaling).call(this);
        /** lắng nghe thông điệp từ iframe */
        __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_listenIframe).call(this);
        /** khởi tạo iframe và tiêm vào trang web */
        __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_initIframe).call(this);
    }
    /**
     * Destroy SDK
     */
    destroy() {
        this.debug('destroy');
        /** Gỡ iframe */
        __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").remove();
        /** Gỡ chặn scaling */
        __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_enableScrollAndRefresh).call(this);
        /** Gỡ chặn scroll */
        __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_enableScrollAndRefresh).call(this);
    }
}
exports.EmbedChatManager = EmbedChatManager;
_EmbedChatManager_PAGE_ID = new WeakMap(), _EmbedChatManager_CONFIG = new WeakMap(), _EmbedChatManager_IFRAME = new WeakMap(), _EmbedChatManager_instances = new WeakSet(), _EmbedChatManager_isMobile = function _EmbedChatManager_isMobile() {
    /* tất cả các màn nhỏ hơn tablet thì là điện thoại */
    /**
     * Các màn nhỏ hơn tablet đều là điện thoại
     */
    const IS_SMALL_SCREEN = window.innerWidth < 768;
    /**
     * Phát hiện màn di động
     */
    const IS_HIGH_DPI = window.devicePixelRatio > 1; // Phát hiện màn hình di động
    /**
     *  return kết quả
     */
    // return IS_SMALL_SCREEN || IS_HIGH_DPI
    return IS_SMALL_SCREEN;
}, _EmbedChatManager_checkViewport = function _EmbedChatManager_checkViewport() {
    /** chỉ cho chạy trên điện thoại */
    if (!__classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_isMobile).call(this))
        return;
    /** kiểm tra nếu chưa tồn tại thẻ thì tạo mới */
    let META_TAG = document.querySelector('meta[name="viewport"]');
    /**
     * Nếu chưa có thẻ thì tạo mới
     */
    if (META_TAG) {
        // Cập nhật trạng thái biến HAS_VIEWPORT
        this.HAS_VIEWPORT = true;
    }
}, _EmbedChatManager_listenIframe = function _EmbedChatManager_listenIframe() {
    window.addEventListener('message', $event => {
        /**dữ liệu iframe gửi cho sdk */
        const PAYLOAD = $event.data;
        /** bóc tách dữ liệu */
        const { from, is_show, is_quick_chat, height, media_url } = PAYLOAD;
        /** nếu không phải từ iframe gửi tới thì thôi */
        if (from !== 'BBH-EMBED-IFRAME')
            return;
        /** thiết lập iframe khi mở popup */
        if (is_show) {
            /** Nếu có request PREVIEW_URL thì xoá margin và thiết lập trạng thái PREVIEW_URL */
            if (media_url) {
                /** Màn điện thoại thì margin = 0 (Ẩn btn và k để margin ) */
                __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframePosition).call(this, 0, 0);
                return __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'PREVIEW_URL');
            }
            /** setup thiết lập iframe khi mở popup,
             * Vì preview ảnh xoá mất margin
             */
            __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframePosition).call(this, env_1.IFRAME_MARGIN_BOTTOM, env_1.IFRAME_MARGIN_RIGHT);
            /** nếu là máy tính thì hiển thị theo setup */
            if (!__classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_isMobile).call(this) && !media_url) {
                /**
                 * Nếu chiều cao hiện tại nhỏ hơn 674px thì thay đổi kích thước frame
                 */
                if (innerHeight < 674) {
                    return __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'PC_OPEN_HEIGHT');
                }
                else
                    return __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'PC_OPEN'); // sửa lại tên env
            }
            /** Màn điện thoại thì margin = 0 (Ẩn btn và k để margin ) */
            __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframePosition).call(this, 0, 0);
            /** nếu là điện thoại thì hiển thị full màn hình // thay thiết lập thành env : MOBILE_IFRAME_WIDTH_OPEN */
            /**
             * Nếu có viewport thì hiển thị full dạng mobile
             * Nếu không có viewport thì hiển thị full dạng PC (để tránh lỗi vì không có viewport)
             */
            if (this.HAS_VIEWPORT) {
                __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'MOBILE_OPEN');
            }
            else {
                /**
                 * Nếu không có viewport thì hiển thị full dạng PC (để tránh lỗi vì không có viewport)
                 */
                __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'PC_OPEN');
                /**
                 * Cài đặt margin cho iframe khi mở popup
                 */
                __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframePosition).call(this, 4, 12);
            }
            /** chặn scroll cha */
            __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_disableScrollAndRefresh).call(this);
        }
        else {
            /** thiết lập iframe khi đóng popup */
            /** mở lại các thuộc tính đã chặn bên trên */
            __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_enableScrollAndRefresh).call(this);
            /** thiết lập margin cách viền theo setup */
            __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframePosition).call(this, env_1.IFRAME_MARGIN_BOTTOM, env_1.IFRAME_MARGIN_RIGHT);
            /** Trạng thái quick chat, điều chỉnh kích thước Iframe về trạng thái QUICK_CHATx */
            if (is_quick_chat)
                return __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'QUICK_CHAT', height);
            /** thu nhỏ về kích thước nút mở popup */
            __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'CLOSE');
        }
    });
}, _EmbedChatManager_scrollToHeader = function _EmbedChatManager_scrollToHeader() {
    window.scrollTo(0, 0);
}, _EmbedChatManager_disableScrollAndRefresh = function _EmbedChatManager_disableScrollAndRefresh() {
    /** Disable scroll and refresh */
    __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_scrollToHeader).call(this);
    /** chặn scroll cha */
    document.body.style.overflow = 'hidden';
    /** Khi thay đổi kích thước thì kéo về đầu trang */
    window.addEventListener('resize', __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_scrollToHeader));
}, _EmbedChatManager_enableScrollAndRefresh = function _EmbedChatManager_enableScrollAndRefresh() {
    /** Enable scroll and refresh */
    document.body.style.overflow = 'auto';
    /** Gỡ event resize */
    window.removeEventListener('resize', __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_scrollToHeader));
}, _EmbedChatManager_disableParentScaling = function _EmbedChatManager_disableParentScaling() {
    this.debug('disableParentScaling', 'isMobile', __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_isMobile).call(this));
    /** chỉ cho chạy trên điện thoại */
    if (!__classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_isMobile).call(this))
        return;
    /**thẻ meta chặn zoom */
    const META_TAG = document.querySelector('meta[name="viewport"]');
    /**nội dung chặn zoom */
    const META_CONTENT = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
    /** nếu đã có thẻ thì thay nội dung */
    if (META_TAG)
        return (META_TAG.content = META_CONTENT);
    /** nếu chưa có thẻ thì tạo mới */
    /**thẻ meta mới tạo */
    const NEW_META_TAG = document.createElement('meta');
    /** tên thẻ */
    NEW_META_TAG.name = 'viewport';
    /** nội dung chặn zoom */
    NEW_META_TAG.content = META_CONTENT;
    /** thêm vào header */
    document.head.appendChild(NEW_META_TAG);
}, _EmbedChatManager_changeIframePosition = function _EmbedChatManager_changeIframePosition(iframe_margin_bottom, iframe_margin_right) {
    /** đặt vị trí xác định */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.position = 'fixed';
    /** cho iframe lên trên cùng index */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.zIndex = '999999';
    /** căn phải */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.right = `${iframe_margin_right}px`;
    /** căn dưới */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.bottom = `${iframe_margin_bottom}px`;
}, _EmbedChatManager_changeIframeSize = function _EmbedChatManager_changeIframeSize(type, height) {
    /** thay đổi kích thước theo từng điều kiện hiển thị */
    switch (type) {
        case 'PREVIEW_URL':
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.width = '100vw';
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.height = '100vh';
            break;
        /** đóng popup */
        case 'CLOSE':
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.width = env_1.IFRAME_WIDTH_CLOSE;
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.height = env_1.IFRAME_HEIGHT_CLOSE;
            break;
        /** Khi mở popup Nhưng chiều cao màn hình nhỏ hơn 674px */
        case 'PC_OPEN_HEIGHT':
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.width = env_1.IFRAME_WIDTH_OPEN;
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.height = '100%';
            break;
        /** khi mở popup trên pc */
        case 'PC_OPEN':
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.width = env_1.IFRAME_WIDTH_OPEN;
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.height = env_1.IFRAME_HEIGHT_OPEN;
            break;
        /** Khi đóng popup nhưng có tin nhắn từ page đến */
        case 'QUICK_CHAT':
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.width = env_1.IFRAME_WIDTH_QUICK_CHAT;
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.height =
                height && height > 0 ? height + 'px' : env_1.IFRAME_HEIGHT_QUICK_CHAT;
            break;
        /** khi mở popup trên dt */
        case 'MOBILE_OPEN':
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.width = '100dvw';
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.height = '100dvh';
            break;
    }
}, _EmbedChatManager_initIframe = function _EmbedChatManager_initIframe() {
    /** loại bỏ khung */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").setAttribute('frameborder', '0');
    /** Thêm id cho iframe */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").setAttribute('id', 'BBH-EMBED-IFRAME');
    /** Thêm Title cho iframe */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").setAttribute('title', 'BBH-EMBED-IFRAME');
    /** Thêm loading cho iframe */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").setAttribute('loading', 'lazy');
    this.debug('initIframe', 'page_id', __classPrivateFieldGet(this, _EmbedChatManager_PAGE_ID, "f"), window.innerWidth, window.innerHeight);
    this.debug('initIframe', 'locale', __classPrivateFieldGet(this, _EmbedChatManager_CONFIG, "f"));
    /** nạp đường dẫn của iframe + id trang + kích thước trang */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").setAttribute('src', `${env_1.IFRAME_SRC}?page_id=${__classPrivateFieldGet(this, _EmbedChatManager_PAGE_ID, "f")}&parentWidth=${window.innerWidth}&parentHeight=${window.innerHeight}&locale=${__classPrivateFieldGet(this, _EmbedChatManager_CONFIG, "f").locale}&has_viewport=${this.HAS_VIEWPORT}`);
    /** ẩn thanh cuộn */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.overflow = 'hidden';
    /** thiết lập vị trí của iframe */
    __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframePosition).call(this, env_1.IFRAME_MARGIN_BOTTOM, env_1.IFRAME_MARGIN_RIGHT);
    /** thay đổi kích thước của iframe: hiện tại mặc định là không mở */
    __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'CLOSE');
    /** tiêm iframe vào trang web */
    document.body.appendChild(__classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f"));
    this.debug('initIframe', 'SUCCESS');
    /** Gửi thông điệp init success */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").onload = () => {
        this.debug('iframe loaded, sending init success message');
        /**
         * Delay 1s để đảm bảo iframe đã load xong
         */
        setTimeout(() => {
            this.debug('delay 1s');
            /**
             * Gửi thông điệp cho parent biết rằng iframe đã được khởi tạo thành công
             */
            window.parent.postMessage({
                from: 'RETION_EMBED',
                type: 'INIT_SUCCESS',
                message: 'Iframe has been initialized successfully!',
            }, '*');
        }, 1000);
        /**
         * Gửi thông điệp cho parent biết rằng iframe đã được khởi tạo thành công
         */
        // window.parent.postMessage(
        //   {
        //     from: 'RETION_EMBED',
        //     type: 'INIT_SUCCESS',
        //     message: 'Iframe has been initialized successfully!',
        //   },
        //   '*'
        // )
    };
};
