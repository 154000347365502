"use strict";
// import { EmbedChatManager } from './embedChatManager'
Object.defineProperty(exports, "__esModule", { value: true });
exports.init = init;
exports.destroy = destroy;
const embedChatManager_1 = require("./embedChatManager");
// /** xuất hàm khởi tạo bong bóng chat */
// export function init(p: InitEmbedChatData) {
//   try {
//     /** khởi tạo sdk */
//     new EmbedChatManager(p.page_id, p.config, p.is_debug).init()
//   } catch (e) {
//     /** bắt lỗi */
//     console.error('BBH error:', e)
//   }
// }
// /** Báo init success cho parent */
// window.postMessage({ type: 'BBH_INIT_SUCCESS' }, '*')
/**
 * Khới tạo 1 biến chat_manager_instance
 */
let chat_manager_instance = null;
function init(p) {
    try {
        /** Khởi tạo sdk */
        chat_manager_instance = new embedChatManager_1.EmbedChatManager(p.page_id, p.config, p.is_debug);
        /**
         * Khởi tạo sdk
         */
        chat_manager_instance.init();
        /** Báo init success cho parent */
        window.postMessage({ type: 'BBH_INIT_SUCCESS' }, '*');
    }
    catch (e) {
        /** Bắt lỗi */
        console.error('BBH error:', e);
    }
}
function destroy() {
    /**
     * Hủy sdk
     */
    if (chat_manager_instance) {
        /**
         * Gọi hàm destroy của chat_manager_instance
         */
        chat_manager_instance.destroy();
        /**
         * Gán giá trị null cho chat_manager_instance
         */
        chat_manager_instance = null;
        console.log('BBH SDK destroyed');
    }
    else {
        /**
         * Nếu không có chat_manager_instance
         */
        console.warn('No chat manager instance to destroy');
    }
}
console.log('run index');
/**
 * gửi thông điệp cho parent
 */
window.parent.postMessage({
    from: 'RETION_EMBED',
    type: 'LOADED_SUCCESS',
    message: 'SDK loaded success',
}, '*');
